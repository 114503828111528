import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AI萧助手',
    component: () => import('../views/Ai/index.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



router.afterEach((to, from) => {
  console.log(to, from)
  document.title = 'AI';
})
export default router
